import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';

import Fab from '@mui/material/Fab';

import { IoChatboxEllipsesOutline } from "@react-icons/all-files/io5/IoChatboxEllipsesOutline";


import { Link } from 'gatsby';
import { IoMenuOutline } from "@react-icons/all-files/io5/IoMenuOutline";
import { IoHomeOutline } from "@react-icons/all-files/io5/IoHomeOutline";
import { IoPeople } from "@react-icons/all-files/io5/IoPeople";
import { IoReaderOutline } from "@react-icons/all-files/io5/IoReaderOutline";

import { IoSearchOutline } from "@react-icons/all-files/io5/IoSearchOutline";
import { IoBookOutline } from "@react-icons/all-files/io5/IoBookOutline";

import { IoBookmarkOutline } from "@react-icons/all-files/io5/IoBookmarkOutline";
import { IoPersonOutline } from "@react-icons/all-files/io5/IoPersonOutline";

 import { GiFilmStrip } from "react-icons/gi";



import AniLink from "gatsby-plugin-transition-link/AniLink"


const links1 = [
            {text: 'Home', title: "Home", href : '/', icon : <IoHomeOutline/>  },
            {text: 'About', title: "About", href : '/about', icon : <IoPersonOutline/>  },
            {text: 'Books', title: "Books", href : '/books',icon : <IoBookOutline/>  },

            {text: 'Talk', title: "Talk", href : '/talk',icon : <IoChatboxEllipsesOutline/>  },
            {text: 'Search', title: "Search", href : '/search',icon : <IoSearchOutline/>  },


            {text: 'Rumours', title: "Rumours", href : '/books/rumours',icon : <IoBookmarkOutline/>  },

            {text: 'Bad Blood', title: "Bad Blood", href : '/books/bad-blood',icon : <IoBookmarkOutline/>  },

            {text: 'Book of Mirrors', title: "Book of Mirrors", href : '/books/book-of-mirrors',icon : <IoBookmarkOutline/>  },

            {text: 'Gods, Weapons and Money', title: "Gods, Weapons and Money", href : '/books/gods-weapons-and-money',icon : <IoBookmarkOutline/>  },


            {text: 'Sleeping Dogs', title: "Sleeping Dogs Trailer", href : '/sleeping-dogs',icon : <GiFilmStrip/>  },


            {text: 'Privacy Policy', title: "Privacy Policy", href : '/privacy-policy', icon : <IoReaderOutline/>  },
            {text: 'Terms and Conditions', title: "Terms and Conditions", href : '/terms-and-conditions',icon : <IoReaderOutline/>  },

              ]; 


 








const linksA = links1.map((link, i) => <ListItem button>  {link.icon}   <Link rel='nofollow noreferrer' title={link.title} to={link.href}><AniLink  bg='white' cover to={link.href}>{link.text}</AniLink></Link>  <Divider /></ListItem>     
 );  







export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });





  const [checked, setChecked] = React.useState(true);

  const handleSwitch = (event) => {


    setChecked(event.target.checked);





  };







  const [open, setOpen] = React.useState(true);




  const handleClick = () => {setOpen(!open); };





  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className='menuList'
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     




      <List> {linksA} </List>










      


    </Box>
  );

  return (
    <div>



    
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>



          


 <Fab onClick={toggleDrawer(anchor, true)} color="primary" aria-label="add">
<IoMenuOutline></IoMenuOutline>
      </Fab>



          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
