exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-bad-blood-js": () => import("./../../../src/pages/books/bad-blood.js" /* webpackChunkName: "component---src-pages-books-bad-blood-js" */),
  "component---src-pages-books-book-of-mirrors-js": () => import("./../../../src/pages/books/book-of-mirrors.js" /* webpackChunkName: "component---src-pages-books-book-of-mirrors-js" */),
  "component---src-pages-books-gods-weapons-and-money-js": () => import("./../../../src/pages/books/gods-weapons-and-money.js" /* webpackChunkName: "component---src-pages-books-gods-weapons-and-money-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-books-rumours-js": () => import("./../../../src/pages/books/rumours.js" /* webpackChunkName: "component---src-pages-books-rumours-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sleeping-dogs-js": () => import("./../../../src/pages/sleeping-dogs.js" /* webpackChunkName: "component---src-pages-sleeping-dogs-js" */),
  "component---src-pages-talk-js": () => import("./../../../src/pages/talk.js" /* webpackChunkName: "component---src-pages-talk-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

