import * as React from "react"
import { Link } from "gatsby"

import CookieNotice from "../components/cookienotice"
import MenuSwipe from "../components/menuswipe"

import AppBar from '@mui/material/AppBar';
import { IoHomeSharp } from "@react-icons/all-files/io5/IoHomeSharp";

import { IoChatboxEllipsesOutline } from "@react-icons/all-files/io5/IoChatboxEllipsesOutline";
import { IoSearchOutline } from "@react-icons/all-files/io5/IoSearchOutline";


import AniLink from "gatsby-plugin-transition-link/AniLink"
import { GiBookmarklet } from "@react-icons/all-files/gi/GiBookmarklet";
 import { GiFilmStrip } from "react-icons/gi";


const Layout = ({ location, title, children }) => {


  return (
      
<div >





      <main>{children}



      </main>






<footer>
<div className='footerContainer'>

<div className='building'>



<GiBookmarklet/>

</div>


<div className='companyName'>Eugen Ovidiu Chirovici </div>


<div className='footerFlexbox'>


        <div className='footerColumn'>

        <Link className='home' to='/' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/">Home</AniLink></Link>
        <Link to='/about' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/about">About</AniLink></Link>
        <Link to='/services' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/books">Books</AniLink></Link>
        <Link to='/talk' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/talk">Talk</AniLink></Link>
        <Link to='/search' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/search">Search</AniLink></Link>

        
        </div>




        <div className='footerColumn'>

        <Link to='/books/book-of-mirrors' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/books/book-of-mirrors">Book of Mirrors</AniLink></Link>
       
        <Link to='/books/bad-blood' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/books/bad-blood">Bad Blood</AniLink></Link>

        <Link to='/books/rumours' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/books/rumours">Rumours</AniLink></Link>
       
        <Link to='/books/gods-weapons-and-money' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/books/gods-weapons-and-money">Gods, Weapons and Money</AniLink></Link>
       
        <Link to='/sleeping-dogs' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/sleeping-dogs">Sleeping Dogs</AniLink></Link>


        </div>



        <div className='footerColumn'>

        <a target='_blank' title='E.O. Chirovici Twitter' href='https://twitter.com/EugenOChirovici'>Twitter</a>

        <a target='_blank' title='E.O. Chirovici Facebook' href='https://www.facebook.com/EugenOvidiuChirovici/'>Facebook</a>

        <Link to='/privacy-policy' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/privacy-policy">Privacy</AniLink></Link>
        
        <Link to='/terms-of-use' activeClassName='activeTab'><AniLink  direction='up' bg='#001533' cover to="/terms-and-conditions">Terms</AniLink></Link>


        </div>




</div>



                <div className='footerCopyright'>

        <span>© Eugen Chirovici. All rights reserved.</span>
  

        </div>




</div>
</footer>












<CookieNotice />





      <div className='menuContainer' >



      <div className='menubar' >


 <Link to='/' activeClassName='activeTab'><AniLink  paintDrip hex="#001533" to="/"><IoHomeSharp/></AniLink></Link>

  <Link to='/about' activeClassName='activeTab'><AniLink swipe direction="left" to="/about">About</AniLink></Link>

 <Link to='/books' activeClassName='activeTab'><AniLink  swipe direction="left" to="/books">Books</AniLink></Link>


 <Link className='filmLink' to='/search' activeClassName='activeTab'><AniLink paintDrip hex="#001533" to="/sleeping-dogs"><GiFilmStrip/>



</AniLink></Link>



 <Link className='chatBotLink' to='/talk' activeClassName='activeTab'><AniLink paintDrip hex="#001533" to="/talk"><IoChatboxEllipsesOutline></IoChatboxEllipsesOutline>
</AniLink></Link>
 <Link className='searchLink' to='/search' activeClassName='activeTab'><AniLink paintDrip hex="#001533" to="/search"><IoSearchOutline></IoSearchOutline>



</AniLink></Link>









<MenuSwipe/>


      </div>


      </div>



















  </div>
  )
}

export default Layout
